import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Row, Col, FormGroup } from "react-bootstrap";
import { Formik, Field } from "formik";
import axios from "axios";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { validationSchemaBuilder } from "../submitFormUtils/validation";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { getFormSizeName } from "../submitFormUtils/getFormSizeName";
import { Stepper, Step } from "react-form-stepper";
import SubmitFormPreview from "./SubmitFormPreview";
import { extractDate } from "../submitFormUtils/extractDate";
import { formattedDate } from "../submitFormUtils/formattedDate";
import { customValidation } from "../submitFormUtils/customValidation";
import { charsNumValidation } from "../submitFormUtils/charsNumValidation";
import {
  handleDecrementDay,
  handleIncrementDay,
  handleDecrementDeathDay,
  handleIncrementDeathDay,
} from "../submitFormUtils/dateSelect";
import { API_URL, UMRLICE_LARAVEL_API_TOKEN } from "../config";

const FormType = {
  Umrlica: "Umrlica",
  Pomen: "Pomen",
  PoslednjiPozdrav: "Posljednji pozdrav",
};
const FormSize = {
  Large: 3,
  Medium: 2,
  Small: 1,
  Tiny: 4,
};

const url = window.location.href;
let multipleImages;
// Find the index of the 'multiple' parameter in the URL
const indexOfMultiple = url.indexOf("multiple=");

if (indexOfMultiple !== -1) {
  // Extract the substring starting from the index of 'multiple=' to the end of the URL
  const substring = url.substring(indexOfMultiple);

  // Split the substring using '&' as a delimiter to get key-value pairs
  const keyValuePairs = substring.split("&");

  // Split the first key-value pair using '=' as a delimiter to get the value of 'multiple'
  const valuePair = keyValuePairs[0].split("=");

  // The second element of the valuePair array contains the value of 'multiple'
  const multipleValue = valuePair[1];
  multipleImages = multipleValue;
}

let uploadedImagePaths = [];
const SubmitForm = (formType, formSize, cities, multiple) => {
  const validationSchema = validationSchemaBuilder(formType, formSize, cities);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedIconUrl, setSelectedIconUrl] = useState("");
  const [step, setStep] = useState(0);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const userRole = localStorage.getItem("userRole");
  const [deathDate, setDeathDate] = useState();
  const [birthDate, setBirthDate] = useState();
  const [iconError, setIconError] = useState("");
  const [imageError, setImageError] = useState("");

  const formikRef = useRef();

  useEffect(() => {
    setDeathDate(formattedDate);
  }, []);

  useEffect(() => {
    const deathDateObj = new Date(deathDate);
    const birthDateObj = new Date(birthDate);

    const deathYear = parseInt(deathDateObj.getFullYear());
    const birthYear = parseInt(birthDateObj.getFullYear());

    const age = deathYear - birthYear;

    if (age <= 18) {
      formikRef.current.setFieldValue("color", "blue");
      document.getElementById("izaberi-boju").value = "blue";
    }
  }, [deathDate, birthDate]);

  Date.prototype.mmddyyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      this.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
  };

  const handleSubmit = async (values, { resetForm }) => {
    let postInformation = {
      note: values.note,
      phone_number: values.phone_number,
      size: formSize,
      name: values.name,
      main_text: values.main_text,
      city: values.city,
      images: uploadedImagePaths,
      icon: null,
      type: null,
      checkbox: null,
      birth_date: null,
      death_date: null,
      header_text: null,
      text_top: values.text_top,
      //text_bottom: values.bottom_text,
      text_bottom: values.bottom_text,
      color: values.color,
    };

    const formData = new FormData();

    formData.append("note", values.note);
    formData.append("phone_number", values.phone_number);

    formData.append("size", formSize);
    formData.append("name", values.name);

    formData.append("main_text", values.main_text);
    formData.append("city", values.city);
    formData.append("images", uploadedImagePaths);
    if (formSize !== FormSize.Tiny) {
      postInformation.icon = selectedIconUrl;
      formData.append("icon", selectedIconUrl);
    } else {
      postInformation.icon = "";
      formData.append("icon", "");
    }

    if (formType === FormType.Umrlica) {
      formData.append("type", "umrlica");
      formData.append("checkbox", "umrlica");

      if (birthDate && deathDate) {
        formData.append("birth_date", birthDate.mmddyyyy());
        formData.append("death_date", deathDate.mmddyyyy());

        postInformation.birth_date = birthDate.mmddyyyy();
        postInformation.death_date = deathDate.mmddyyyy();
      }

      postInformation.type = "umrlica";
      postInformation.checkbox = "umrlica";
    } else if (formType === FormType.PoslednjiPozdrav) {
      formData.append("type", "poslednji_pozdrav");
      formData.append("checkbox", "poslednji_pozdrav");
      postInformation.type = "poslednji_pozdrav";
      postInformation.checkbox = "poslednji_pozdrav";
    } else if (formType === FormType.Pomen) {
      formData.append("type", "pomen");

      formData.append("checkbox", "pomen");
      formData.append("header_text", values.header_text);

      postInformation.type = "pomen";
      postInformation.checkbox = "pomen";
      postInformation.header_text = values.header_text;
    }
    formData.append("text_top", values.text_top);
    formData.append("text_bottom", values.bottom_text);
    formData.append("color", values.color);

    formData.append("main_text", values.main_text);

    localStorage.setItem("postToSave", JSON.stringify(formData));

    /*setTimeout(() => {
      if (userRole === "superadmin") {
        window.location.href = "/user-profile";
      } else {
        setTimeout(() => {
          NotificationManager.success(
              `Prebacujemo Vas na stranicu za plaćanje.`
          );
        }, 1000);

        setTimeout(() => {
          window.location.href = "/payment";
        }, 1500);
      }
    }, 500);*/

    try {
      const response = await axios.post(`${API_URL}/api/posts/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      postInformation.id = response.data.id;

      NotificationManager.success("Učitavanje stranice za plaćanje…");

      /*
 -   PLACE THE CODE BELOW TO RUN AFTER SUCCESSFUL PAYMMENT -
    NotificationManager.success(
        `${formType} uspješno ${formType === "Umrlica"
          ? "dodata! Bićete obaviješteni kada admin potvrdi umrlicu."
          : `dodat! Bićete obaviješteni kada admin potvrdi ${formType}.`
        }`
      ); */

      setTimeout(async () => {
        if (userRole === "superadmin") {
          window.location.href = "/user-profile";
        } else {
          const updateCart = await axios.get(
            `https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/update?user_id=${localStorage.getItem(
              "userId"
            )}&data=${JSON.stringify(postInformation)}`
          );
          setTimeout(() => {
            window.location.href = "/payment";
          }, 400);
        }
      }, 700);

      // Reset form values
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };
  let topTextLimit = 100;
  let nameLimit = 40;
  let mainTextLimit = 320;
  let bottomTextLimit = 120;

  const charsValidation = charsNumValidation(
    formSize,
    formType,
    topTextLimit,
    nameLimit,
    mainTextLimit,
    bottomTextLimit
  );
  const handleReplaceImage = (index) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const newImageFile = event.target.files[0];
      const newSelectedImages = [...selectedImages];

      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

      const maxSize = 25 * 1024 * 1024;
      if (newImageFile.size > maxSize) {
        setImageError("Maksimalna veličina fajla je 25MB");
      } else if (!allowedTypes.includes(newImageFile?.type)) {
        setImageError("Samo JPG i PNG formati su dozvoljeni");
      } else {
        setImageError("");
        newSelectedImages[index] = newImageFile;

        setSelectedImages(newSelectedImages);
      }
    };
    input.click();
  };

  const handleAddImage = () => {
    if (multipleImages === "true") {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";

      input.onchange = (event) => {
        const newImage = event.target.files[0];
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

        const maxSize = 25 * 1024 * 1024;
        if (newImage.size > maxSize) {
          setImageError("Maksimalna veličina fajla je 25MB");
        } else if (!allowedTypes.includes(newImage?.type)) {
          setImageError("Samo JPG i PNG formati su dozvoljeni");
        } else {
          setImageError("");
          setSelectedImages([...selectedImages, newImage]);
        }
      };

      input.click();
    } else if (multipleImages === "false") {
      if (selectedImages.length < 1) {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (event) => {
          const newImage = event.target.files[0];
          const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

          if (!allowedTypes.includes(newImage?.type)) {
            setImageError("Samo JPG i PNG formati su dozvoljeni");
          } else {
            setImageError("");
          }

          setSelectedImages([...selectedImages, newImage]);
        };
        input.click();
      } else {
        NotificationManager.error(
          "Izabrali ste objavu sa samo jednom fotografijom"
        );
      }
    }
  };

  const handleDeleteImage = (index) => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages.splice(index, 1);
    setSelectedImages(newSelectedImages);
  };

  const stepForward = async (values, validateForm, errors) => {
    validateForm();
    if (errors.name) {
      return NotificationManager.error(errors.name);
    }
    setIsSubmiting(true);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    for (let i = 0; i < selectedImages.length; i++) {
      if (!allowedTypes.includes(selectedImages[i]?.type)) {
        setIsSubmiting(false);
        return NotificationManager.error("Pogresan format fajla fotografije");
      }
    }
    if (formType === "Umrlica") {
      if (birthDate && !deathDate) {
        setIsSubmiting(false);
        return NotificationManager.error(
          `Ako postavite datum rodjenja, datum smrti je takodje obavezan.`
        );
      }
      if (deathDate && !birthDate) {
        setIsSubmiting(false);
        return NotificationManager.error(
          `Ako postavite datum smrti, datum rodjenja je takodje obavezan.`
        );
      }

      if (birthDate > deathDate) {
        setIsSubmiting(false);
        return NotificationManager.error(
          `Datum rodjenja ne moze biti veći od datuma smrti.`
        );
      }
    }
    uploadedImagePaths = [];
    if (selectedImages && selectedImages.length > 0) {
      for (const imageFile of selectedImages) {
        let imgFormData = new FormData();
        imgFormData.append("image", imageFile);

        try {
          const response = await axios.post(
            `${API_URL}/api/posts/upload_image/`,
            imgFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );

          const imagePath = response.data.path;
          // Check if the imagePath already exists in the uploadedImagePaths
          const existingImageIndex = uploadedImagePaths.findIndex(
            (path) => path === imagePath
          );

          if (existingImageIndex !== -1) {
            // Replace the existing image with the new one
            uploadedImagePaths[existingImageIndex] = imagePath;
          } else {
            // If not found, add the new image path to the array
            uploadedImagePaths.push(imagePath);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    customValidation(
      formType,
      formSize,
      values,
      setIsSubmiting,
      setStep,
      uploadedImagePaths,
      NotificationManager,
      deathDate,
      birthDate,
      step
    );
  };

  const stepBack = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsSubmiting(false);
    setStep(step - 1);
  };
  const handleKeyDown = (e, values, type, setFieldValue) => {
    if (e.keyCode === 13) {
      setFieldValue(type, values[type] + "\n");
    }
  };

  return (
    <div style={{ paddingTop: "120px" }}>
      <Stepper activeStep={step}>
        <Step label="Informacije o objavi" />

        <Step label="Pregled" />
        <Step label="Potvrda" />
      </Stepper>

      <Formik
        initialValues={{
          name: "",
          text_top: "",
          main_text: "",
          icon: "",
          image: null,
          checkbox: "",
          size: "",
          bottom_text: "",
          color: "",
          header_text: "",
          city: "",
          note: "",
          phone_number: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
          handleChange,
          validateForm,
          errors,
        }) => (
          <Form
            className="submit-form"
            onSubmit={handleSubmit}
            style={{
              margin: "0 auto",
              marginTop: "3%",
              maxWidth: "1000px",
              padding: "20px",
              backgroundColor: "#f5f5f5",
              borderRadius: "5px",
              border: "1px solid gray",
              marginBottom: "4%",
            }}
          >
            <div>
              {step !== 1 && step !== 2 && (
                <div>
                  <Row>
                    <Col>
                      {" "}
                      <div
                        style={{
                          height: "0.5px",
                          border: "1px solid black",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      ></div>
                    </Col>
                    <Col>
                      <h5 style={{ textAlign: "center", width: "auto" }}>
                        {formType}
                      </h5>
                    </Col>
                    <Col>
                      {" "}
                      <div
                        style={{
                          height: "0.5x",
                          border: "1px solid black",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      ></div>
                    </Col>
                  </Row>
                  {formSize === 3 && formType === "Pomen" && (
                    <Row>
                      <Col lg={4}></Col>
                      <Col lg={4}>
                        <Form.Group>
                          <h6>Gornji tekst</h6>

                          <Field
                            placeholder="Unesite gornji tekst..."
                            name="text_top"
                            onChange={handleChange}
                            value={values.text_top}
                            className="FormaZaPopunjavanje-input"
                            style={{ marginTop: "8px" }}
                            maxLength={160} // Set maximum length for the input
                          />
                          {errors.text_top && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.text_top}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.text_top.length}/160
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={4}></Col>
                    </Row>
                  )}
                  {formSize !== 4 &&
                    formType === "Posljednji pozdrav" &&
                    !(formSize === 2 && formType === "Posljednji pozdrav") && (
                      <Row>
                        <Col lg={4}></Col>
                        <Col lg={4}>
                          <Form.Group>
                            <h6>Gornji tekst </h6>

                            <Field
                              placeholder="Unesite gornji tekst..."
                              name="text_top"
                              onChange={handleChange}
                              value={values.text_top}
                              className="FormaZaPopunjavanje-input"
                              style={{ marginTop: "8px" }}
                              maxLength={160} // Set maximum length for the input
                            />
                            {errors.text_top && (
                              <p style={{ color: "red", width: "100%" }}>
                                {errors.text_top}
                              </p>
                            )}
                            <span className="char-counter">
                              {values.text_top.length}/160
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={4}></Col>
                      </Row>
                    )}
                  {formSize === 3 && formType === "Umrlica" && (
                    <Row>
                      <Col lg={4}></Col>
                      <Col lg={4}>
                        <Form.Group>
                          <h6>Gornji tekst</h6>

                          <Field
                            placeholder="Unesite gornji tekst..."
                            name="text_top"
                            onChange={handleChange}
                            value={values.text_top}
                            className="FormaZaPopunjavanje-input"
                            style={{ marginTop: "8px" }}
                            maxLength={160} // Set maximum length for the input
                          />
                          {errors.text_top && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.text_top}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.text_top.length}/160
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={4}></Col>
                    </Row>
                  )}
                  {formSize !== 4 &&
                    formType !== "Umrlica" &&
                    formSize === 2 && (
                      <Row>
                        <Col lg={4}></Col>
                        <Col lg={4}>
                          <Form.Group>
                            <h6>Gornji tekst </h6>

                            <Field
                              placeholder="Unesite gornji tekst..."
                              name="text_top"
                              onChange={handleChange}
                              value={values.text_top}
                              className="FormaZaPopunjavanje-input"
                              style={{ marginTop: "8px" }}
                              maxLength={160} // Set maximum length for the input
                            />
                            {errors.text_top && (
                              <p style={{ color: "red", width: "100%" }}>
                                {errors.text_top}
                              </p>
                            )}
                            <span className="char-counter">
                              {values.text_top.length}/160
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={4}></Col>
                      </Row>
                    )}
                  {(formSize === 3 || formSize === 2) && (formType === "Umrlica" || formType === "Pomen") && (
                    <Row>
                      <Col lg={4}></Col>
                      <Col style={{ marginTop: "3%" }} lg={4}>
                        <h6>Izaberi ikonicu</h6>
                        <Select
                          options={[
                            {
                              value: "Bez ikonice",
                              label: "Bez ikonice",
                            },
                            {
                              value: `${API_URL}/media/icons/icon1.png`,
                              label: (
                                <img
                                  height={20}
                                  width={20}
                                  src={require("../assets/ikonice/icon1.png")}
                                  alt="Icon 1"
                                />
                              ),
                            },
                            {
                              value: `${API_URL}/media/icons/icon2.png`,
                              label: (
                                <img
                                  height={20}
                                  width={20}
                                  src={require("../assets/ikonice/icon2.png")}
                                  alt="Icon 2"
                                />
                              ),
                            },
                            {
                              value: `${API_URL}/media/icons/icon3.png`,
                              label: (
                                <img
                                  height={20}
                                  width={20}
                                  src={require("../assets/ikonice/icon3.png")}
                                  alt="Icon 3"
                                />
                              ),
                            },
                            {
                              value: `${API_URL}/media/icons/icon4.png`,
                              label: (
                                <img
                                  height={20}
                                  width={20}
                                  src={require("../assets/ikonice/icon4.png")}
                                  alt="Icon 4"
                                />
                              ),
                            },
                            {
                              value: `${API_URL}/media/icons/icon5.png`,
                              label: (
                                <img
                                  height={20}
                                  width={20}
                                  src={require("../assets/ikonice/icon5.png")}
                                  alt="Icon 5"
                                />
                              ),
                            },
                          ]}
                          onChange={(selectedOption) => {
                            if (
                              selectedOption.value ===
                              `${API_URL}/media/icons/icon4.png`
                            ) {
                              setFieldValue("color", "green");
                              document.getElementById("izaberi-boju").value =
                                "green";
                            }

                            // Update the selectedIconUrl state
                            setSelectedIconUrl(selectedOption.value);
                            // Update the "icon" field value
                            setFieldValue("icon", selectedOption.value);
                          }}
                          value={
                            selectedIconUrl && selectedIconUrl !== "Bez ikonice"
                              ? {
                                value: selectedIconUrl,
                                label: (
                                  <div>
                                    <img
                                      height={20}
                                      width={20}
                                      src={selectedIconUrl}
                                      alt="Selected Icon"
                                    />
                                    <span style={{ marginLeft: "10px" }}>
                                      Izabrana ikonica
                                    </span>
                                  </div>
                                ),
                              }
                              : null
                          }
                        />{" "}
                        <div style={{ color: "red" }}>{iconError}</div>
                      </Col>
                      <Col lg={4}></Col>
                    </Row>
                  )}
                  {formType === "Pomen" && (
                    <Row>
                      <Col lg={4}></Col>
                      <Col lg={4}>
                        <Form.Group>
                          <h6>Tekst iznad slike</h6>
                          <Field
                            required
                            placeholder="Unesite tekst iznad slike"
                            name="header_text"
                            onChange={handleChange}
                            value={values.header_text}
                            className="FormaZaPopunjavanje-input"
                            style={{ marginTop: "8px" }}
                            maxLength={50} // Set maximum length for the input
                          />
                          {errors.header_text && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.header_text}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.header_text.length}/50
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={4}></Col>
                    </Row>
                  )}
                  {formSize !== 4 && (
                    <div>
                      <h5 style={{ marginTop: "3%", textAlign: "center" }}>
                        Fotografija <span style={{ color: "red" }}>*</span>
                      </h5>
                    </div>
                  )}
                  {selectedImages.map((image, index) => (
                    <div
                      key={index}
                      style={{
                        marginRight: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {image instanceof File ? (
                          <img
                            src={URL.createObjectURL(image)} // Use createObjectURL to display local image
                            alt={`Izabrana slika ${index}`}
                            style={{
                              maxWidth: "120px",
                              maxHeight: "120px",
                              marginRight: "10px",
                            }}
                          />
                        ) : (
                          <img
                            src={
                              image.startsWith("http")
                                ? image
                                : `${API_URL}/` + image
                            }
                            alt={`Izabrana slika ${index}`}
                            style={{
                              maxWidth: "120px",
                              maxHeight: "120px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        )}

                        <div style={{ display: "flex", marginTop: "5px" }}>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteImage(index)}
                          >
                            X
                          </Button>
                          <Button
                            size="sm"
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleReplaceImage(index)}
                          >
                            Promijeni
                          </Button>
                        </div>
                      </div>
                      <hr
                        style={{
                          maxWidth: "300px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      ></hr>
                    </div>
                  ))}
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    {imageError}
                  </div>
                  {selectedImages.length < 3 && (
                    <div style={{ marginRight: "20px", marginBottom: "20px" }}>
                      {formSize !== 4 && (
                        <center>
                          <Button
                            variant="success"
                            size="sm"
                            onClick={handleAddImage}
                          >
                            Dodaj
                          </Button>
                        </center>
                      )}
                      <div
                        style={{
                          paddingTop: "1%",
                          fontSize: "smaller",
                          textAlign: "center",
                          color: "gray",
                          fontStyle: "italic",
                        }}
                      >
                        Preporučena razmjera fotografija je <b>9:16</b>
                      </div>
                    </div>
                  )}
                  <Row>
                    <Col lg={4}></Col>
                    <Col lg={4}>
                      <FormGroup>
                        <h6>
                          Ime i prezime <span style={{ color: "red" }}>*</span>
                        </h6>
                        <Field
                          required
                          placeholder="Unesite ime i prezime..."
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          className="FormaZaPopunjavanje-input"
                          style={{ marginTop: "8px" }}
                          maxLength={50}
                        />
                        {errors.name && (
                          <p style={{ color: "red", width: "100%" }}>
                            {errors.name}
                          </p>
                        )}
                        <span className="char-counter">
                          {values.name.length}/50
                        </span>
                      </FormGroup>
                    </Col>
                    <Col lg={4}></Col>
                  </Row>

                  {formType === FormType.Umrlica && (
                    <div className="container" style={{ textAlign: "center" }}>
                      <h6>Datum rodjenja</h6>
                      <Col xs={12} className="d-flex justify-content-center">
                        <button
                          disabled={!birthDate}
                          style={{
                            border: "none",
                            background: "transparent",
                            padding: "0",
                            margin: "0",
                          }}
                          onClick={() =>
                            handleDecrementDay(birthDate, setBirthDate)
                          }
                        >
                          <img
                            src={require("../assets/lijevo.png")}
                            alt="neki tekst"
                            style={{
                              height: "20px",
                              border: "none",
                              margin: "0",
                            }}
                          />
                        </button>
                        <DatePicker
                          required
                          name="birth_date"
                          onChange={setBirthDate}
                          value={birthDate}
                          format={"dd/MM/y"}
                        />
                        <button
                          disabled={!birthDate}
                          style={{
                            border: "none",
                            background: "transparent",
                            padding: "0",
                            margin: "0",
                          }}
                          onClick={() =>
                            handleIncrementDay(birthDate, setBirthDate)
                          }
                        >
                          <img
                            src={require("../assets/desno.png")}
                            alt="neki tekst"
                            style={{
                              height: "20px",
                              border: "none",
                              margin: "0",
                            }}
                          />
                        </button>{" "}
                      </Col>{" "}
                      <h6>Datum smrti</h6>
                      <Col xs={12} className="d-flex justify-content-center">
                        <button
                          disabled={!deathDate}
                          style={{
                            border: "none",
                            background: "transparent",
                            padding: "0",
                            margin: "0",
                          }}
                          onClick={() =>
                            handleDecrementDeathDay(deathDate, setDeathDate)
                          }
                        >
                          <img
                            src={require("../assets/lijevo.png")}
                            alt="neki tekst"
                            style={{
                              height: "20px",
                              border: "none",
                              margin: "0",
                            }}
                          />
                        </button>
                        <DatePicker
                          required
                          name="death_date"
                          onChange={setDeathDate}
                          value={deathDate}
                          format="dd/MM/y"
                        />
                        <button
                          disabled={!deathDate}
                          style={{
                            border: "none",
                            background: "transparent",
                            padding: "0",
                            margin: "0",
                          }}
                          onClick={() =>
                            handleIncrementDeathDay(deathDate, setDeathDate)
                          }
                        >
                          <img
                            src={require("../assets/desno.png")}
                            alt="neki tekst"
                            style={{
                              height: "20px",
                              border: "none",
                              margin: "0",
                            }}
                          />
                        </button>
                      </Col>
                    </div>
                  )}

                  {formSize === 2 && (
                    <Form.Group>
                      <h6>
                        Glavni tekst <span style={{ color: "red" }}>*</span>
                      </h6>
                      <Field
                        onKeyDown={(e) =>
                          handleKeyDown(e, values, "main_text", setFieldValue)
                        }
                        required
                        placeholder="Unesite glavni tekst..."
                        name="main_text"
                        as="textarea"
                        rows={4}
                        onChange={handleChange}
                        value={values.main_text}
                        className="FormaZaPopunjavanje-textarea"
                        style={{ marginTop: "8px" }}
                        maxLength={charsValidation.mainTextLimit} // Set maximum length for the input
                      />
                      {errors.main_text && (
                        <p style={{ color: "red", width: "100%" }}>
                          {errors.main_text}
                        </p>
                      )}
                      <span className="char-counter">
                        {values.main_text.length}/
                        {charsValidation.mainTextLimit}
                      </span>
                    </Form.Group>
                  )}
                  {formSize === 4 && formType === "Pomen" && (
                    <Form.Group>
                      <h6>
                        Glavni tekst <span style={{ color: "red" }}>*</span>
                      </h6>
                      <Field
                        onKeyDown={(e) =>
                          handleKeyDown(e, values, "main_text", setFieldValue)
                        }
                        required
                        placeholder="Unesite glavni tekst..."
                        name="main_text"
                        as="textarea"
                        rows={4}
                        onChange={handleChange}
                        value={values.main_text}
                        className="FormaZaPopunjavanje-textarea"
                        style={{ marginTop: "8px" }}
                        maxLength={50} // Set maximum length for the input
                      />
                      {errors.main_text && (
                        <p style={{ color: "red", width: "100%" }}>
                          {errors.main_text}
                        </p>
                      )}
                      <span className="char-counter">
                        {values.main_text.length}/{50}
                      </span>
                    </Form.Group>
                  )}

                  {formSize === 1 && formType === "Posljednji pozdrav" && (
                    <Form.Group>
                      <h6>
                        Glavni tekst <span style={{ color: "red" }}>*</span>
                      </h6>
                      <Field
                        onKeyDown={(e) =>
                          handleKeyDown(e, values, "main_text", setFieldValue)
                        }
                        required
                        placeholder="Unesite glavni tekst..."
                        name="main_text"
                        as="textarea"
                        rows={4}
                        onChange={handleChange}
                        value={values.main_text}
                        className="FormaZaPopunjavanje-textarea"
                        style={{ marginTop: "8px" }}
                        maxLength={120} // Set maximum length for the input
                      />{" "}
                      {errors.main_text && (
                        <p style={{ color: "red", width: "100%" }}>
                          {errors.main_text}
                        </p>
                      )}
                      <span className="char-counter">
                        {values.main_text.length}/{120}
                      </span>
                    </Form.Group>
                  )}

                  {formSize === 1 && formType === "Umrlica" && (
                    <Form.Group>
                      <h6>
                        Glavni tekst <span style={{ color: "red" }}>*</span>
                      </h6>
                      <Field
                        onKeyDown={(e) =>
                          handleKeyDown(e, values, "main_text", setFieldValue)
                        }
                        required
                        placeholder="Unesite glavni tekst..."
                        name="main_text"
                        as="textarea"
                        rows={4}
                        onChange={handleChange}
                        value={values.main_text}
                        className="FormaZaPopunjavanje-textarea"
                        style={{ marginTop: "8px" }}
                        maxLength={120} // Set maximum length for the input
                      />{" "}
                      {errors.main_text && (
                        <p style={{ color: "red", width: "100%" }}>
                          {errors.main_text}
                        </p>
                      )}
                      <span className="char-counter">
                        {values.main_text.length}/{120}
                      </span>
                    </Form.Group>
                  )}
                  {formSize === 3 && (
                    <Form.Group>
                      <h6>
                        Glavni tekst <span style={{ color: "red" }}>*</span>
                      </h6>
                      <Field
                        onKeyDown={(e) =>
                          handleKeyDown(e, values, "main_text", setFieldValue)
                        }
                        required
                        placeholder="Unesite glavni tekst..."
                        name="main_text"
                        as="textarea"
                        rows={4}
                        onChange={handleChange}
                        value={values.main_text}
                        className="FormaZaPopunjavanje-textarea"
                        style={{ marginTop: "8px" }}
                        maxLength={charsValidation.mainTextLimit} // Set maximum length for the input
                      />{" "}
                      {errors.main_text && (
                        <p style={{ color: "red", width: "100%" }}>
                          {errors.main_text}
                        </p>
                      )}
                      <span className="char-counter">
                        {values.main_text.length}/
                        {charsValidation.mainTextLimit}
                      </span>
                    </Form.Group>
                  )}
                  {formSize === 3 && formType === "Pomen" && (
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <h6>
                            {formType === FormType.Umrlica
                              ? "Ožalošćeni "
                              : "Od"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h6>
                          <Field
                            required
                            placeholder="Unesite tekst..."
                            name="bottom_text"
                            rows={4}
                            onChange={handleChange}
                            value={values.bottom_text}
                            className="FormaZaPopunjavanje-textarea"
                            style={{ marginTop: "8px" }}
                            maxLength={charsValidation.bottomTextLimit}
                            as="textarea"
                          />
                          {errors.bottom_text && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.bottom_text}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.bottom_text.length}/
                            {charsValidation.bottomTextLimit}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                  )}
                  {formSize === 3 && formType === "Posljednji pozdrav" && (
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <h6>
                            {formType === FormType.Umrlica
                              ? "Ožalošćeni "
                              : "Od"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h6>
                          <Field
                            required
                            placeholder="Unesite tekst..."
                            name="bottom_text"
                            rows={4}
                            onChange={handleChange}
                            value={values.bottom_text}
                            className="FormaZaPopunjavanje-textarea"
                            style={{ marginTop: "8px" }}
                            maxLength={charsValidation.bottomTextLimit}
                            as="textarea"
                          />
                          {errors.bottom_text && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.bottom_text}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.bottom_text.length}/
                            {charsValidation.bottomTextLimit}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                  )}
                  {formSize === 1 && formType !== "Posljednji pozdrav" && (
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <h6>
                            {formType === FormType.Umrlica
                              ? "Ožalošćeni "
                              : "Od"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h6>
                          <Field
                            onKeyDown={(e) =>
                              handleKeyDown(
                                e,
                                values,
                                "bottom_text",
                                setFieldValue
                              )
                            }
                            required
                            placeholder="Unesite tekst..."
                            name="bottom_text"
                            rows={4}
                            onChange={handleChange}
                            value={values.bottom_text}
                            className="FormaZaPopunjavanje-textarea"
                            style={{
                              marginTop: "8px",
                            }}
                            maxLength={charsValidation.bottomTextLimit}
                            as="textarea"
                          />
                          {errors.bottom_text && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.bottom_text}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.bottom_text.length}/
                            {charsValidation.bottomTextLimit}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                  )}
                  {formSize === 1 && formType === "Posljednji pozdrav" && (
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <h6>
                            {formType === FormType.Umrlica
                              ? "Ožalošćeni "
                              : "Od"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h6>
                          <Field
                            onKeyDown={(e) =>
                              handleKeyDown(
                                e,
                                values,
                                "bottom_text",
                                setFieldValue
                              )
                            }
                            required
                            placeholder="Unesite tekst..."
                            name="bottom_text"
                            onChange={handleChange}
                            value={values.bottom_text}
                            className="FormaZaPopunjavanje-textarea"
                            style={{
                              marginTop: "8px",
                            }}
                            maxLength={charsValidation.bottomTextLimit}
                            as="textarea"
                          />
                          {errors.bottom_text && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.bottom_text}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.bottom_text.length}/
                            {charsValidation.bottomTextLimit}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                  )}

                  {formSize === 2 && formType !== "Umrlica" && (
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <h6>
                            {formType === FormType.Umrlica
                              ? "Ožalošćeni "
                              : "Od"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h6>
                          <Field
                            onKeyDown={(e) =>
                              handleKeyDown(
                                e,
                                values,
                                "bottom_text",
                                setFieldValue
                              )
                            }
                            required
                            placeholder="Unesite tekst..."
                            name="bottom_text"
                            rows={4}
                            onChange={handleChange}
                            value={values.bottom_text}
                            className="FormaZaPopunjavanje-textarea"
                            style={{ marginTop: "8px" }}
                            maxLength={charsValidation.bottomTextLimit}
                            as="textarea"
                          />{" "}
                          {errors.bottom_text && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.bottom_text}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.bottom_text.length}/
                            {charsValidation.bottomTextLimit}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                  )}
                  {formSize === 4 && formType === "Umrlica" && (
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <h6>
                            {formType === FormType.Umrlica
                              ? "Ožalošćeni "
                              : "Od"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h6>
                          <Field
                            onKeyDown={(e) =>
                              handleKeyDown(
                                e,
                                values,
                                "bottom_text",
                                setFieldValue
                              )
                            }
                            required
                            placeholder="Unesite tekst..."
                            name="bottom_text"
                            rows={4}
                            onChange={handleChange}
                            value={values.bottom_text}
                            className="FormaZaPopunjavanje-textarea"
                            style={{ marginTop: "8px" }}
                            maxLength={charsValidation.bottomTextLimit}
                            as="textarea"
                          />{" "}
                          {errors.bottom_text && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.bottom_text}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.bottom_text.length}/
                            {charsValidation.bottomTextLimit}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                  )}

                  {formSize === 2 && formType === "Umrlica" && (
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <h6>
                            {formType === FormType.Umrlica
                              ? "Ožalošćeni "
                              : "Od"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h6>
                          <Field
                            onKeyDown={(e) =>
                              handleKeyDown(
                                e,
                                values,
                                "bottom_text",
                                setFieldValue
                              )
                            }
                            required
                            placeholder="Unesite tekst..."
                            name="bottom_text"
                            rows={4}
                            onChange={handleChange}
                            value={values.bottom_text}
                            className="FormaZaPopunjavanje-textarea"
                            style={{ marginTop: "8px" }}
                            maxLength={charsValidation.bottomTextLimit}
                            as="textarea"
                          />{" "}
                          {errors.bottom_text && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.bottom_text}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.bottom_text.length}/
                            {charsValidation.bottomTextLimit}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                  )}

                  {formSize === 3 && formType === "Umrlica" && (
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <h6>
                            {formType === FormType.Umrlica
                              ? "Ožalošćeni "
                              : "Od"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h6>
                          <Field
                            onKeyDown={(e) =>
                              handleKeyDown(
                                e,
                                values,
                                "bottom_text",
                                setFieldValue
                              )
                            }
                            required
                            placeholder="Unesite tekst..."
                            name="bottom_text"
                            rows={4}
                            onChange={handleChange}
                            value={values.bottom_text}
                            className="FormaZaPopunjavanje-textarea"
                            style={{ marginTop: "8px" }}
                            maxLength={charsValidation.bottomTextLimitt}
                            as="textarea"
                          />{" "}
                          {errors.bottom_text && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.bottom_text}
                            </p>
                          )}
                          <span className="char-counter">
                            {values.bottom_text.length}/
                            {charsValidation.bottomTextLimit}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col lg={6}></Col>
                    </Row>
                  )}

                  <hr></hr>

                  <div>
                    <Row>
                      <Col lg={6}>
                        <Form.Group>
                          <h6>Izaberi boju</h6>
                          <Form.Control
                            required
                            name="color"
                            as="select"
                            onChange={handleChange}
                            value={values.color}
                            className="FormaZaPopunjavanje-select"
                            style={{ marginTop: "8px" }}
                            id="izaberi-boju"
                          >
                            <option value="">Izaberi boju</option>
                            <option style={{ color: "black" }} value="black">
                              Crna
                            </option>
                            <option style={{ color: "blue" }} value="blue">
                              Plava
                            </option>
                            <option style={{ color: "green" }} value="green">
                              Zelena
                            </option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group>
                          <h6>
                            Grad <span style={{ color: "red" }}>*</span>
                          </h6>
                          <Field
                            style={{
                              borderRadius: "8px",
                              padding: "1%",
                              width: "100%",
                            }}
                            name="city"
                            as="select"
                          >
                            {cities.length !== 0 && (
                              <option defaultValue={"Izaberi grad"}>
                                Izaberi grad
                              </option>
                            )}

                            {cities.length === 0 && (
                              <option defaultValue={"Izaberi grad"}>
                                Gradovi se ocitavaju...
                              </option>
                            )}
                            {cities.map((city, index) => {
                              return (
                                <option key={index} value={city.id}>
                                  {city.name}
                                </option>
                              );
                            })}
                          </Field>{" "}
                          {errors.city && (
                            <p style={{ color: "red", width: "100%" }}>
                              {errors.city}
                            </p>
                          )}
                        </Form.Group>{" "}
                      </Col>
                    </Row>
                  </div>
                </div>
              )}

              {step === 2 && step !== 1 && (
                <div>
                  <h6>
                    Molimo vas ostavite vaš kontakt telefon i/ili napomenu kako
                    bi naši administratori mogli da vas pozovu ukoliko bude bilo
                    potrebe za dodatnim informacijama
                  </h6>
                  <Col sm={6}>
                    <b>
                      {" "}
                      <h5> Email:{localStorage.getItem("loggedInUserName")}</h5>
                    </b>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Field
                        required
                        type="phone"
                        placeholder="Vaš broj telefona ..."
                        name="phone_number"
                        onChange={handleChange}
                        value={values.phone_number}
                        className="FormaZaPopunjavanje-input"
                        style={{ marginTop: "8px" }}
                      />
                      {errors.phone_number && (
                        <p style={{ color: "red", width: "100%" }}>
                          {errors.phone_number}
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group>
                      <Form.Control
                        placeholder="Napomena o objavi..."
                        name="note"
                        as="textarea"
                        rows={4}
                        onChange={handleChange}
                        value={values.note}
                        className="FormaZaPopunjavanje-textarea"
                        style={{ marginTop: "8px" }}
                      />
                    </Form.Group>
                  </Col>
                </div>
              )}
              {step === 1 && (
                <div>
                  <h3 style={{ textAlign: "center" }}>Pregled umrlice</h3>
                  <SubmitFormPreview
                    size={getFormSizeName(formSize)}
                    type={formType}
                    color={values.color}
                    icon={values.icon}
                    main_text={values.main_text}
                    name={values.name}
                    image={uploadedImagePaths[0]}
                    bottom_text={values.bottom_text}
                    headerText={values.header_text}
                    deathDate={extractDate(deathDate)}
                    birthDate={extractDate(birthDate)}
                    text_top={values.text_top}
                  />
                </div>
              )}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {step !== 3 && step !== 0 && (
                  <Button
                    className="FormaZaPopunjavanje-continue"
                    type="button"
                    style={{
                      background: "darkred",
                      border: "none",
                      marginTop: "20px",
                    }}
                    onClick={() => stepBack()}
                  >
                    Nazad
                  </Button>
                )}
                {step === 2 && (
                  <Button
                    onClick={() => console.log(errors)}
                    className="FormaZaPopunjavanje-submit"
                    type="submit"
                  >
                    Potvrdi
                  </Button>
                )}
                {step !== 2 && (
                  <Button
                    disabled={isSubmiting}
                    className="FormaZaPopunjavanje-continue"
                    onClick={() => stepForward(values, validateForm, errors)}
                    style={{
                      marginTop: "20px",
                      marginLeft: "auto", // This pushes the button to the right
                    }}
                  >
                    {isSubmiting ? "Učitavanje..." : "Nastavi"}
                  </Button>
                )}
              </div>
            </div>

            <NotificationContainer animation={false} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { SubmitForm, FormType, FormSize };
