import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";
import { shortenString } from "../utils";
import ShareButtons from "./ShareButtons";
import { API_URL } from "../config";

const convertDate = (date) => {
  const [year, month, day] = date.split("-");

  if (day == undefined) {
    return year;
  }

  return [day, month, year].join("/");
};

const fullDate = (birth_date, death_date) => {
  if (birth_date && death_date) {
    return `${convertDate(birth_date)} - ${convertDate(death_date)}`;
  }

  return null;
}

function BigUmrlica({ post, modalIsOpen }) {
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const userRole = localStorage.getItem("userRole");
  const [counter, setCounter] = useState(post.counter);

  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(`${API_URL}/api/posts/${post.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
      increaseCounter();
    }
  };
  const increaseCounter = async () => {
    try {
      setCounter(post.counter + 1);
      await axios.post(`${API_URL}/api/posts/${post.id}/counter/up/`, {});
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const customStyle = {
    border: `5px solid ${post && post.color === "blue"
      ? "blue" // Use the specific color for "blue"
      : post.color === "green" ||
        post.color === "black" ||
        post.color === "red"
        ? post.color // Use other colors for "green," "black," or "red"
        : "black" // Default color for other cases
      }`,
    backgroundColor: "white",
    marginTop: "20px",
    width: "100%",
  };

  return (
    <Container
      id={`post-${post.id}`}
      className="post"
      style={customStyle}
      data-aos="fade-up"
    >
      {" "}
      <Row
        style={{
          fontStyle: "italic",
          border: "none",
          borderTop: "none",
          display: "flex",
          justifyContent: "end",

          paddingRight: "0.5vh",
          backgroundColor: "transparent",
          color: "gray",
          fontSize: "smaller",
        }}
      >
        {post.type}
      </Row>{" "}
      <Col
        style={{ marginTop: "30px" }}
        className="d-flex justify-content-center"
      >
        {post.icon !== "Bez ikonice" && (
          <img
            id="umrlica-icon"
            style={{
              height: "50px",
              width: "50px",
              border: "none",
            }}
            src={post.icon}
            alt=""
          />
        )}
      </Col>
      <Row style={{ paddingTop: "1%" }}>
        <p style={{ paddingRight: "10%" }}>{post.text_top}</p>
      </Row>
      <Row id="umrlica-img-row" style={{ paddingTop: "2%" }}>
        <Col className="d-flex justify-content-around">
          {post.images.length !== 0 && (
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
            >
              {post.images
                .reduce((p, c) => p + c)
                .split(",")
                .map((imagePath, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <div
                      style={{
                        width: "120px",
                        height: "150px",
                        border: "3px solid black",
                        background: `url(${API_URL}/${imagePath})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                      }}
                    ></div>
                  </div>
                ))}
            </Carousel>
          )}

          {post.images.length === 0 && (
            <div
              style={{
                width: "150px",
                height: "150px",
              }}
            ></div>
          )}
        </Col>
      </Row>
      <Row style={{ paddingTop: "1%" }}>
        <Col
          style={{
            fontWeight: "bold",
            letterSpacing: "1.2px",
            fontSize: "larger",
          }}
          className="d-flex justify-content-around"
        >
          {post.name}
        </Col>
      </Row>
      <Col className="d-flex justify-content-around">
        <span style={{ textAlign: "center", paddingTop: "1%" }}>
          {fullDate(post.birth_date, post.death_date)}
        </span>
      </Col>
      {!modalIsOpen && (
        <Row className="lg-umrlica-main-text" style={{ paddingTop: "2%" }}>
          <span className="main-text" style={{ textAlign: "left" }}>
            {post.main_text.includes("\n")
              ? post.main_text
                .split("\n")
                .slice(0, 3)
                .map((line, index, array) => (
                  <React.Fragment key={index}>
                    {shortenString(line, 150)}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))
              : shortenString(post.main_text, 150)}
            {post.main_text.split("\n").length > 2 && "..."}
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row className="lg-umrlica-main-text" style={{ paddingTop: "2%" }}>
          <span className="main-text" style={{ textAlign: "left" }}>
            {post.main_text.split("\n").map((s, index) => (
              <p key={index} className="main-text-line">
                {s.trim()}
              </p>
            ))}
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <span style={{ textAlign: "center", fontStyle: "italic" }}>
            Upali svijeću
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              alignItems: "center",
              borderRadius: "100%",
            }}
            onClick={handleCandleClick}
          >
            <img src={imageSrc} alt="Image" />
          </button>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ paddingBottom: "0" }}>
          {" "}
          <span style={{ textAlign: "center" }}>{counter}</span>
        </Row>
      )}
      <hr className="lg-umrlica-hr"></hr>
      {!modalIsOpen && (
        <Row style={{ padding: "2%", paddingLeft: "0" }}>
          <b>
            <h5 id="sakrij">Ožalošćeni:</h5>
            <p id="sakrij">{shortenString(post.text_bottom, 120)}</p>
          </b>
        </Row>
      )}{" "}
      {modalIsOpen && (
        <Row style={{ padding: "2%", paddingLeft: "0" }}>
          <b>
            <h5 id="sakrij">Ožalošćeni:</h5>
            <p id="sakrij">
              {
                <div
                  className="p-text-bottom"
                  style={{ fontWeight: "bold", paddingTop: "1%" }}
                >
                  {post.text_bottom.split("\n").map((s, index) => (
                    <p className="bottom-text-line">{s.trim()}</p>
                  ))}
                </div>
              }
            </p>
          </b>
        </Row>
      )}
      {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
        <Col>
          <button
            style={{ marginLeft: "3%" }}
            className="btn btn-danger"
            onClick={() => deletePosts()}
          >
            Obriši
          </button>
        </Col>
      )}
      {!modalIsOpen && (
        <div className="predaj-pp-button">Predaj poslednji pozdrav</div>
      )}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default BigUmrlica;
