import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles

import { shortenString } from "../utils";
import ShareButtons from "./ShareButtons";

import { API_URL } from "../config";

function Pomen({ post, modalIsOpen }) {
  const userRole = localStorage.getItem("userRole");
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const [counter, setCounter] = useState(post.counter);

  const handleCandleClick = async () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }

    try {
      await increaseCounter();
    } catch (error) {
      console.log("Error updating counter:", error);
    }
  };

  const increaseCounter = async () => {
    try {
      await axios.post(
        `${API_URL}/api/posts/${post.id}/counter/up/`,
        {}
      );
      setCounter(post.counter + 1);
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  if (counter === null) {
    return <div>No pomen posts available.</div>;
  }

  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(
        `${API_URL}/api/posts/${post.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const modalStyle = {
    border: "7px double black",
    backgroundColor: "white",
    height: "317px",
  };

  return (
    <Container
      data-aos="fade-up"
      className="post"
      id="pomen"
      style={modalIsOpen ? { ...modalStyle, height: "auto" } : modalStyle}
    >
      {" "}
      <Row>
        <span
          style={{
            fontStyle: "italic",
            border: "none",
            borderTop: "none",
            textAlign: "right",
            backgroundColor: "white",
            color: "gray",
            fontSize: "smaller",
            paddingRight: "0.5vh",
            paddingBottom: "1%",
          }}
        >
          {post.type === "poslednji_pozdrav" ? "posljednji pozdrav" : post.type}
        </span>
      </Row>
      <p style={{ padding: "0%", fontSize: "14px" }}>{post.text_top}</p>
      <Row>
        <Col className="d-flex justify-content-around"></Col>
        <Col className="d-flex justify-content-around">
          {post.images.length !== 0 && (
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
            >
              {post.images
                .reduce((p, c) => p + c)
                .split(",")
                .map((imagePath, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <div
                      style={{
                        width: "110px",
                        height: "130px",
                        border: "3px solid black",
                        background: `url(${API_URL}/${imagePath})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                      }}
                    ></div>
                  </div>
                ))}
            </Carousel>
          )}

          {post.images.length === 0 && (
            <div
              style={{
                width: "140px",
                height: "140px",
              }}
            ></div>
          )}
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <Row style={{}}>
        <Col className="d-flex justify-content-around"></Col>
        <p
          style={{
            fontWeight: "bold",
            letterSpacing: "1.2px",
            paddingTop: "1%",
          }}
          className="d-flex justify-content-around"
        >
          {post.name}
        </p>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      {modalIsOpen && (
        <Row style={{ paddingLeft: "2%" }}>
          <div
            className="main-text"
            style={{ padding: "0%", textAlign: "left" }}
          >
            {post.main_text.split("\n").map((s, index) => (
              <p key={index} className="main-text-line">
                {s.trim()}
              </p>
            ))}
          </div>
        </Row>
      )}
      {!modalIsOpen && (
        <Row style={{ paddingLeft: "2%" }}>
          <div
            className="main-text"
            style={{ padding: "0%", textAlign: "left" }}
          >
            <span className="main-text" style={{ textAlign: "left" }}>
              {post.main_text.includes("\n")
                ? post.main_text
                  .split("\n")
                  .slice(0, 2)
                  .map((line, index, array) => (
                    <React.Fragment key={index}>
                      {shortenString(line, 100)}
                      {index < array.length - 1 && <br />}
                    </React.Fragment>
                  ))
                : shortenString(post.main_text, 100)}
              {post.main_text.split("\n").length > 2 && "..."}
            </span>
          </div>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <span style={{ textAlign: "center", fontStyle: "italic" }}>
            Upali svijeću
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              alignItems: "center",
              borderRadius: "100%",
            }}
            onClick={handleCandleClick}
          >
            <img src={imageSrc} alt="Image" />
          </button>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ paddingBottom: "0" }}>
          {" "}
          <span style={{ textAlign: "center" }}>{counter}</span>
        </Row>
      )}
      {!modalIsOpen && (
        <Row style={{}}>
          <b>
            <p style={{ float: "right", fontSize: "15px" }}>
              {shortenString(post.text_bottom, 20)}
            </p>
          </b>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{}}>
          <b>
            <div style={{ float: "right", fontSize: "15px" }}>
              {post.text_bottom.split("\n").map((s, index) => (
                <p className="bottom-text-line">{s.trim()}</p>
              ))}
            </div>
          </b>
        </Row>
      )}
      {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
        <Col>
          <button
            style={{ marginLeft: "3%" }}
            className="btn btn-danger"
            onClick={() => deletePosts()}
          >
            Obriši
          </button>
        </Col>
      )}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default Pomen;
