import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";

import { shortenString } from "../utils";
import ShareButtons from "./ShareButtons";

import { API_URL } from "../config";

function SmallPoslednjiPozdrav({ post, type, modalIsOpen }) {
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const [counter, setCounter] = useState(post.counter);
  const userRole = localStorage.getItem("userRole");
  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const increaseCounter = async () => {
    try {
      setCounter(post.counter + 1);
      await axios.post(
        `${API_URL}/api/posts/${post.id}/counter/up/`,
        {}
      );
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };
  if (!post) {
    return <div>Loading...</div>;
  }
  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(
        `${API_URL}/api/posts/${post.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const modalStyle = {
    border: "3px solid black",
    backgroundColor: "white",
    alignItems: "center",
    maxHeight: "100%",
    height: "317px",
    fontSize: "small",
    maxHeight: "auto",
  };
  return (
    <Container
      data-aos={"fade-up"}
      className="post"
      id={modalIsOpen ? "pp-sm-open" : "pp-sm"}
      style={modalIsOpen ? { ...modalStyle, height: "auto" } : modalStyle}
    >
      {" "}
      <Row>
        <span
          className="post-type"
          style={{
            fontStyle: "italic",
            border: "none",
            borderTop: "none",
            textAlign: "right",
            backgroundColor: "white",
            color: "gray",
            fontSize: "smaller",
          }}
        >
          {post.type === "poslednji_pozdrav" ? "posljednji pozdrav" : post.type}
        </span>
      </Row>
      <Row>
        <span style={{ textAlign: "center", paddingBottom: "1%" }}>
          {post.text_top}
        </span>
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <Col className="d-flex justify-content-center"></Col>
      </Row>
      <Row>
        <Col className=" justify-content-around"></Col>
        <Col className="d-flex justify-content-around">
          {post.images.length !== 0 && (
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
            >
              {post.images
                .reduce((p, c) => p + c)
                .split(",")
                .map((imagePath, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <div
                      style={{
                        width: "110px",
                        height: "130px",
                        border: "3px solid black",
                        background: `url(${API_URL}/${imagePath})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                      }}
                    ></div>
                  </div>
                ))}
            </Carousel>
          )}
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <span
        className="d-flex justify-content-around"
        style={{
          textAlign: "center",
          fontSize: "1rem",
          letterSpacing: "1.2px",
          paddingTop: "2%",
        }}
      >
        {post.name}
      </span>
      {!modalIsOpen && (
        <Row>
          <span className="main-text" style={{ textAlign: "left" }}>
            {post.main_text.includes("\n")
              ? post.main_text
                .split("\n")
                .slice(0, 2)
                .map((line, index, array) => (
                  <React.Fragment key={index}>
                    {shortenString(line, 100)}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))
              : shortenString(post.main_text, 100)}
            {post.main_text.split("\n").length > 2 && "..."}
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          <span style={{ textAlign: "left" }}>
            {" "}
            {post.main_text.split("\n").map((s, index) => (
              <p key={index} className="main-text-line">
                {s.trim()}
              </p>
            ))}
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <span style={{ textAlign: "center", fontStyle: "italic" }}>
            Upali svijeću
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              alignItems: "center",
              borderRadius: "100%",
            }}
            onClick={handleCandleClick}
          >
            <img src={imageSrc} alt="Image" />
          </button>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ paddingBottom: "0" }}>
          {" "}
          <span style={{ textAlign: "center" }}>{counter}</span>
        </Row>
      )}
      <Row
        style={{
          padding: "2%",
          paddingLeft: "0",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        {" "}
        <hr style={{ margin: "0", marginBottom: "1%" }}></hr>
        <span
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Ožalošćeni {"..."}
        </span>
        {modalIsOpen && (
          <div
            style={{
              fontWeight: "bold",
              paddingTop: "1%",
            }}
          >
            {post.text_bottom.split("\n").map((s, index) => (
              <p className="bottom-text-line">{s.trim()}</p>
            ))}
          </div>
        )}
      </Row>{" "}
      {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
        <Col>
          <button
            style={{ marginLeft: "3%" }}
            className="btn btn-danger"
            onClick={() => deletePosts()}
          >
            Obriši
          </button>
        </Col>
      )}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default SmallPoslednjiPozdrav;
