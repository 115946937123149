import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../config";

import ShareButtons from "./ShareButtons";
function QuarterPP({ post, modalIsOpen }) {
  const [counter, setCounter] = useState(post.counter);
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(
        `${API_URL}/api/posts/${post.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const increaseCounter = async () => {
    try {
      setCounter(post.counter + 1);
      await axios.post(
        `${API_URL}/api/posts/${post.id}/counter/up/`,
        {}
      );
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const userRole = localStorage.getItem("userRole");
  return (
    <Container
      style={{ height: `${modalIsOpen ? "auto" : "147.5px"}` }}
      className="post poslednji-pozdrav"
      data-aos={"fade-up"}
    >
      {" "}
      <Row
        style={{
          fontStyle: "italic",
          border: "none",
          borderTop: "none",
          display: "flex",
          justifyContent: "end",
          paddingRight: "0.5rem",
          paddingRight: "0.5vh",
          backgroundColor: "transparent",
          color: "gray",
          fontSize: "smaller",
        }}
      >
        {post.type === "poslednji_pozdrav" ? "posljednji pozdrav" : post.type}
      </Row>
      <Row lg={12} md={12} sm={12}>
        {" "}
        <h4
          style={{
            fontSize: "20px",
            marginTop: "2.5rem",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {post.name}
        </h4>
        {modalIsOpen && (
          <Row
            style={{
              paddingBottom: "0",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {" "}
            <span style={{ textAlign: "center", fontStyle: "italic" }}>
              Upali svijeću
            </span>
          </Row>
        )}
        {modalIsOpen && (
          <Row
            style={{
              paddingBottom: "0",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {" "}
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                alignItems: "center",
                borderRadius: "100%",
              }}
              onClick={handleCandleClick}
            >
              <img src={imageSrc} alt="Image" />
            </button>
          </Row>
        )}
        {modalIsOpen && (
          <Row
            style={{
              paddingBottom: "0",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {" "}
            <span style={{ textAlign: "center" }}>{counter}</span>
          </Row>
        )}
        {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
          <Col>
            <button
              style={{ marginBottom: "2%", float: "left" }}
              className="btn btn-danger"
              onClick={() => deletePosts()}
            >
              Obriši
            </button>
          </Col>
        )}
        {modalIsOpen && <ShareButtons id={post.id} />}
      </Row>{" "}
    </Container>
  );
}

export default QuarterPP;
