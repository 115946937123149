// Umrlica.js
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { shortenString } from "../utils";

import ShareButtons from "./ShareButtons";

import { API_URL } from "../config";

function SmallUmrlica({ post, modalIsOpen, userRole }) {
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const [counter, setCounter] = useState(post.counter);

  const increaseCounter = async () => {
    try {
      await axios.post(
        `${API_URL}/api/posts/${post.id}/counter/up/`,
        {}
      );
      setCounter(post.counter + 1);
    } catch (error) {
      console.log("Error updating counter:", error);
    }
  };

  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(
        `${API_URL}/api/posts/${post.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const convertDate = (date) => {
    const [year, month, day] = date.split("-");

    if (day == undefined) {
      return year;
    }

    return [day, month, year].join("/");
  };
  const fullDate = (birth_date, death_date) => {
    if (birth_date && death_date) {
      return `${convertDate(birth_date)} - ${convertDate(death_date)}`;
    }

    return null;
  }


  const modalStyle = {
    border: `5px solid ${post && post.color === "blue"
      ? "blue" // Use the specific color for "blue"
      : post.color === "green" ||
        post.color === "black" ||
        post.color === "red"
        ? post.color // Use other colors for "green," "black," or "red"
        : "black" // Default color for other cases
      }`,
    backgroundColor: "white",
    height: "317px",
    fontSize: "small",
  };
  return (
    <Container
      data-aos="fade-up"
      className="post"
      style={modalIsOpen ? { ...modalStyle, height: "auto" } : modalStyle}
    >
      <Row
        style={{
          fontStyle: "italic",
          border: "none",
          borderTop: "none",
          display: "flex",
          justifyContent: "end",
          paddingRight: "0.5rem",

          backgroundColor: "transparent",
          color: "gray",
          fontSize: "smaller",
        }}
      >
        {post.type}
      </Row>
      <Row>
        <Col className="d-flex justify-content-around">
          {post.images.length !== 0 && (
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
              style={{ height: "fit-content" }}
            >
              {post.images
                .reduce((p, c) => p + c)
                .split(",")
                .map((imagePath, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <div
                      style={{
                        width: "110px",
                        height: "130px",
                        border: "3px solid black",
                        background: `url(${API_URL}/${imagePath})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                      }}
                    ></div>
                  </div>
                ))}
            </Carousel>
          )}
        </Col>
      </Row>
      <Row
        className="d-flex justify-content-around"
        style={{
          fontWeight: "bold",
          letterSpacing: "1.2px",
          paddingTop: "2%",
        }}
      >
        {post.name}
      </Row>
      <Row
        style={{
          textAlign: "center",
          paddingLeft: "2%",
          paddingRight: "2%",
          paddingTop: "1%",
        }}
      >
        <span style={{ textAlign: "center" }}>
          {fullDate(post.birth_date, post.death_date)}
        </span>
      </Row>
      {!modalIsOpen && (
        <Row style={{ paddingTop: "1%" }}>
          <span className="main-text" style={{ textAlign: "left" }}>
            {post.main_text.includes("\n")
              ? post.main_text
                .split("\n")
                .slice(0, 2)
                .map((line, index, array) => (
                  <React.Fragment key={index}>
                    {shortenString(line, 150)}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))
              : shortenString(post.main_text, 150)}
            {post.main_text.split("\n").length > 2 && "..."}
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ paddingTop: "1%" }}>
          <span className="main-text" style={{ textAlign: "left" }}>
            {post.main_text.split("\n").map((s, index) => (
              <p key={index} className="main-text-line">
                {s.trim()}
              </p>
            ))}
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ paddingTop: "2%" }}>
          {" "}
          <span style={{ textAlign: "center", fontStyle: "italic" }}>
            Upali svijeću
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              alignItems: "center",
              borderRadius: "100%",
            }}
            onClick={handleCandleClick}
          >
            <img src={imageSrc} alt="Image" />
          </button>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ paddingBottom: "1%" }}>
          {" "}
          <span style={{ textAlign: "center" }}>{counter}</span>
        </Row>
      )}
      {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
        <Col>
          <button
            style={{ marginLeft: "3%" }}
            className="btn btn-danger"
            onClick={() => deletePosts()}
          >
            Obriši
          </button>
        </Col>
      )}
      <Row style={{ padding: "2%", paddingLeft: "0" }}>
        <hr style={{ margin: "0", marginBottom: "1%" }}></hr>
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          Ožalošćeni...
        </span>
        {modalIsOpen && (
          <div
            className="p-text-bottom"
            style={{ fontWeight: "bold", paddingTop: "1%" }}
          >
            {post.text_bottom.split("\n").map((s, index) => (
              <p className="bottom-text-line">{s.trim()}</p>
            ))}
          </div>
        )}
      </Row>{" "}
      {!modalIsOpen && (
        <div>
          <div className="predaj-pp-button">Predaj posljednji pozdrav</div>
        </div>
      )}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default SmallUmrlica;
